import React, { useCallback, useEffect, useState } from 'react'
import { Button, ButtonGroup, Input, ScrollShadow, Textarea } from '@nextui-org/react'
import { RefreshCcwIcon, TrashIcon, UploadCloud } from 'lucide-react'
import { useDropzone } from 'react-dropzone'
import api from 'src/lib/api'
import { Icon } from '@iconify/react'
import toast from 'react-hot-toast'

export default function DocumentSettings({ isSettingsOpen, close, urs }) {
  const [footer, setFooter] = useState<string>('')
  const [header, setHeader] = useState<string>('')
  const [logo, setLogo] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [currentPage, setCurrentPage] = useState<'DOC_SETTING' | 'CLIENT_INFO'>('DOC_SETTING')

  const [clientPhone, setClientPhone] = useState<string>(urs?.clientPhone)
  const [clientEmail, setClientEmail] = useState<string>(urs?.clientEmail)
  const [clientName, setClientName] = useState<string>(urs?.client)
  const [clientRole, setClientRole] = useState<string>(urs?.clientPhone)
  const [clientAgency, setClientAgency] = useState<string>(urs?.clientAgency)
  const [loading, setLoading] = useState<boolean>(false)
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0])
    if (acceptedFiles[0]) {
      const preview = URL.createObjectURL(acceptedFiles[0])
      setLogo(preview)
    }
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/*', // Accept specific file types if needed
    maxFiles: 1, // Limit to one file at a time
  })
  const loadMyConfig = async () => {
    const res = await api.get('/urs/config')
    if (res.data) {
      setFooter(res.data?.footer ?? '')
      setHeader(res.data?.header ?? '')
      setLogo(res.data?.logo ?? '')
    }
  }
  const applyConfig = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('footer', footer)
    formData.append('header', header)
    if (file) {
      formData.append('file', file)
    }
    const res = await api.post('/urs/config', formData)
    setLoading(false)
    close()
  }
  const deleteFile = async () => {
    setLogo('')
    const res = await api.post('/urs/config', {
      logo: '',
    })
    setLoading(false)
  }

  const updateClient = async () => {
    setLoading(true)
    const res = await api.post(`/urs/${urs.uuid}`, {
      client: clientName,
      clientAgency,
      clientRole,
      clientPhone,
      clientEmail,
    })
    toast.success('Client information has been updated successfully.')
    close?.()
    setLoading(false)
  }

  useEffect(() => {
    loadMyConfig()
  }, [])
  return (
    <ScrollShadow className="h-full" hideScrollBar size={100} id="settings">
      <div className={'flex flex-row my-4'}>
        <div className={'flex flex-row justify-center flex-1 align-middle'}>
          <ButtonGroup>
            <Button
              onClick={() => setCurrentPage('DOC_SETTING')}
              variant={currentPage === 'DOC_SETTING' ? 'solid' : 'bordered'}
              color={'secondary'}
              className={currentPage === 'DOC_SETTING' ? 'text-white' : 'text-secondary'}
              startContent={<Icon icon="lucide:file" width="20" />}
            >
              <span className={currentPage === 'DOC_SETTING' ? 'text-white' : 'text-secondary'}>Document Settings</span>
            </Button>
            <Button
              onClick={() => setCurrentPage('CLIENT_INFO')}
              variant={currentPage === 'CLIENT_INFO' ? 'solid' : 'bordered'}
              color={'secondary'}
              startContent={
                <Icon
                  className={currentPage === 'CLIENT_INFO' ? 'text-white' : 'text-secondary'}
                  icon="lucide:user"
                  width="20"
                />
              }
            >
              <span className={currentPage === 'CLIENT_INFO' ? 'text-white' : 'text-secondary'}>Client & Cover</span>
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {currentPage === 'CLIENT_INFO' ? (
        <div className="flex flex-col gap-2">
          <h2 className={'text-xl font-bold'}>Client info & Cover page</h2>
          <div className={'flex flex-col gap-2 mt-2'}>
            <Input
              className="mt-1"
              label="Client name"
              type="text"
              variant={'bordered'}
              placeholder="e.g. John Doe"
              value={clientName}
              classNames={{
                inputWrapper:
                  'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
              }}
              onChange={(e) => setClientName(e.target.value)}
            />
            <Input
              className="mt-1"
              label="Client role"
              type="text"
              variant={'bordered'}
              placeholder="e.g. CEO"
              value={clientRole}
              classNames={{
                inputWrapper:
                  'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
              }}
              onChange={(e) => setClientRole(e.target.value)}
            />
            <Input
              className="mt-1"
              label="Client agency"
              type="text"
              variant={'bordered'}
              placeholder="e.g. Doe Agency"
              value={clientAgency}
              classNames={{
                inputWrapper:
                  'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
              }}
              onChange={(e) => setClientAgency(e.target.value)}
            />
            <Input
              className="mt-1"
              label="Client email"
              type="text"
              variant={'bordered'}
              placeholder="e.g. john.doe@agency.com"
              value={clientEmail}
              classNames={{
                inputWrapper:
                  'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
              }}
              onChange={(e) => setClientEmail(e.target.value)}
            />
            <Input
              className="mt-1"
              label="Client phone"
              type="tel"
              variant={'bordered'}
              placeholder="e.g. +1 (555) 555-1234"
              value={clientPhone}
              classNames={{
                inputWrapper:
                  'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
              }}
              onChange={(e) => setClientPhone(e.target.value)}
            />
            <div className={'flex flex-col gap-2'}>
              <Button variant="solid" color={'secondary'} className={''} onClick={updateClient}>
                Update client info
              </Button>
              <p className="text-tiny">Applies your client information to the cover page</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <h2 className={'text-xl font-bold'}>Document settings</h2>
          <div className={'flex flex-col gap-2 mt-2'}>
            <h3 className={'text-medium font-medium'}>Custom header</h3>
            <Textarea
              variant={'bordered'}
              placeholder={
                'e.g.\nACME Inc.\nAddress: 123 Market St, Springfield, CA 90210\nPhone: (555) 123-4567 | Email: contact@acme.com'
              }
              classNames={{
                inputWrapper:
                  'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
                input: 'font-medium',
              }}
              value={header}
              onChange={(e) => setHeader(e.target.value)}
              minRows={5}
              maxRows={5}
            />
          </div>
          <div className={'flex  flex-col gap-2 mt-2'}>
            <h3 className={'text-medium font-medium'}>Custom footer</h3>
            <Textarea
              variant={'bordered'}
              placeholder={'e.g.\n© 2024 ACME Inc. All rights reserved.\nEmpowering progress, one solution at a time.'}
              classNames={{
                inputWrapper:
                  'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
                input: 'font-medium',
              }}
              value={footer}
              onChange={(e) => setFooter(e.target.value)}
              minRows={5}
              maxRows={5}
            />
          </div>
          <div {...getRootProps({ className: 'flex flex-col gap-2 mt-2' })}>
            <input {...getInputProps()} />
            <h3 className={'text-medium font-medium'}>Custom logo</h3>
            <div className={'flex flex-col gap-1 justify-center items-center p-4 border-1 border-secondary rounded-xl'}>
              {logo ? (
                <>
                  <img src={logo} className={'w-[120px] mb-5'} />
                  <div className={'flex gap-2'}>
                    <Button variant="solid" color={'secondary'} className={''} onClick={open}>
                      <RefreshCcwIcon />
                      Select file
                    </Button>
                    <Button variant="bordered" color={'danger'} className={''} onClick={deleteFile}>
                      <TrashIcon />
                      Delete File
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <UploadCloud size={60} color={'#65d0ca'} className={'mb-2'} />
                  <p className={'font-medium text-xl'}>Select a file or drag and drop here</p>
                  <span className={'text-sm font-regular'}>JPG or PNG, file size no more than 10MB</span>
                  <Button variant="solid" color={'secondary'} className={''} onClick={open}>
                    Select file
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className={'flex flex-col gap-2 mt-2'}>
            <Button variant="solid" color={'secondary'} className={''} onClick={applyConfig}>
              Save
            </Button>
            <p className="text-tiny">Applies your custom header, footer, and logo to all documents.</p>
          </div>
        </div>
      )}
    </ScrollShadow>
  )
}
