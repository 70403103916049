import { NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash.debounce'

export const CoverPageComponent = ({ node, getPos, editor, updateAttributes }: NodeViewProps) => {
  // @ts-ignore
  const coverState = useRef<any>({
    date: '',
    title: '',
    yourName: '',
    yourAgency: '',
    yourAgencyRole: '',
    yourEmail: '',
    yourPhone: '',
    clientName: '',
    clientEmail: '',
    clientAgency: '',
    clientPhone: '',
    clientRole: '',
  })
  const [date, setDate] = useState('')
  const [title, setTitle] = useState('')

  const [yourName, setYourName] = useState('')
  const [yourAgency, setYourAgency] = useState('')
  const [yourAgencyRole, setYourAgencyRole] = useState('')
  const [yourEmail, setYourEmail] = useState('')
  const [yourPhone, setYourPhone] = useState('')

  const [clientName, setClientName] = useState('')
  const [clientEmail, setClientEmail] = useState('')
  const [clientAgency, setClientAgency] = useState('')
  const [clientPhone, setClientPhone] = useState('')
  const [clientRole, setClientRole] = useState('')

  useEffect(() => {
    const initialContent = (node.content?.content[0]?.text || '').split('@@PARAM@@')
    if (initialContent.length >= 10) {
      setDate(initialContent[0])
      setTitle(initialContent[1])
      setYourName(initialContent[2])
      setYourAgencyRole(initialContent[3])
      setYourAgency(initialContent[4])
      setYourPhone(initialContent[5])
      setYourEmail(initialContent[6])
      setClientName(initialContent[7])
      setClientRole(initialContent[8])
      setClientAgency(initialContent[9])
      setClientPhone(initialContent[10])
      setClientEmail(initialContent[11])
      coverState.current = {
        date: initialContent[0],
        title: initialContent[1],
        yourName: initialContent[2],
        yourAgency: initialContent[3],
        yourAgencyRole: initialContent[4],
        yourEmail: initialContent[5],
        yourPhone: initialContent[6],
        clientName: initialContent[7],
        clientEmail: initialContent[8],
        clientAgency: initialContent[9],
        clientPhone: initialContent[10],
        clientRole: initialContent[11],
      }
    }
  }, [node.content])
  const debouncedUpdateAttributes = useMemo(
    () =>
      debounce((data: string[]) => {
        updateAttributes({ content: data.join('@@PARAM@@') })
      }, 300), // 300ms debounce delay
    [updateAttributes],
  )
  // Handler to manage content changes
  const handleContentChange = (index: number, value: string) => {
    const data = [
      coverState.current.date,
      coverState.current.title,
      coverState.current.yourName,
      coverState.current.yourAgencyRole,
      coverState.current.yourAgency,
      coverState.current.yourPhone,
      coverState.current.yourEmail,
      coverState.current.clientName,
      coverState.current.clientRole,
      coverState.current.clientAgency,
      coverState.current.clientPhone,
      coverState.current.clientEmail,
    ]
    data[index] = value
    switch (index) {
      case 0:
        coverState.current.date = value
        break
      case 1:
        coverState.current.title = value
        break
      case 2:
        coverState.current.yourName = value
        break
      case 3:
        coverState.current.yourAgencyRole = value
        break
      case 4:
        coverState.current.yourAgency = value
        break
      case 5:
        coverState.current.yourPhone = value
        break
      case 6:
        coverState.current.yourEmail = value
        break
      case 7:
        coverState.current.clientName = value
        break
      case 8:
        coverState.current.clientRole = value
        break
      case 9:
        coverState.current.clientAgency = value
        break
      case 10:
        coverState.current.clientPhone = value
        break
      case 11:
        coverState.current.clientEmail = value
        break
    }
    // Update the node attributes with debounce
    debouncedUpdateAttributes(data)
  }

  return (
    <NodeViewWrapper className="cover-page-section relative">
      <div className={'cover-page flex flex-col'} contentEditable={false}>
        <div
          style={{
            display: 'table',
            minHeight: '60%',
          }}
        >
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              paddingBottom: '20%',
            }}
          >
            <div
              className="date"
              contentEditable={true}
              suppressContentEditableWarning
              onInput={(e) => handleContentChange(0, e.currentTarget?.textContent ?? '')}
            >
              {date}
            </div>
            <div
              className="main-title"
              contentEditable={true}
              suppressContentEditableWarning
              onInput={(e) => handleContentChange(1, e.currentTarget?.textContent ?? '')}
            >
              {title}
            </div>
          </div>
        </div>
        <div className="page-info">
          <table className={'w-full'}>
            <tr className={'w-full'}>
              <td
                style={{
                  width: '50%',
                  display: 'table-cell',
                  verticalAlign: 'top',
                }}
              >
                <div className="section">
                  <div className="section-title">Created by:</div>
                  <div className="section-content">
                    <strong
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(2, e.currentTarget?.textContent ?? '')}
                    >
                      {yourName}
                    </strong>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(3, e.currentTarget?.textContent ?? '')}
                    >
                      {yourAgencyRole}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(4, e.currentTarget?.textContent ?? '')}
                    >
                      {yourAgency}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(5, e.currentTarget?.textContent ?? '')}
                    >
                      {yourPhone}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(6, e.currentTarget?.textContent ?? '')}
                    >
                      {yourEmail}
                    </span>
                    <br />
                  </div>
                </div>
              </td>
              <td
                style={{
                  width: '50%',
                  display: 'table-cell',
                  verticalAlign: 'top',
                }}
              >
                <div className="section">
                  <div className="section-title">Prepared for:</div>
                  <div className="section-content">
                    <strong
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(7, e.currentTarget?.textContent ?? '')}
                    >
                      {clientName}
                    </strong>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(8, e.currentTarget?.textContent ?? '')}
                    >
                      {clientRole}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(9, e.currentTarget?.textContent ?? '')}
                    >
                      {clientAgency}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(10, e.currentTarget?.textContent ?? '')}
                    >
                      {clientPhone}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(11, e.currentTarget?.textContent ?? '')}
                    >
                      {clientEmail}
                    </span>
                    <br />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </NodeViewWrapper>
  )
}
