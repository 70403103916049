import { mergeAttributes, Node } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'
// Utility to find the row
import { DOMSerializer } from '@tiptap/pm/model'

const stopPrevent = <T extends Event>(e: T): T => {
  ;(e as Event).stopPropagation()
  ;(e as Event).preventDefault()

  return e
}

export interface TableRowOptions {
  HTMLAttributes: Record<string, any>
}

const isScrollable = function (ele: any) {
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight

  const overflowYStyle = window.getComputedStyle(ele).overflowY
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1

  return hasScrollableContent && !isOverflowHidden
}

const getScrollableParent = function (ele: any): any {
  // eslint-disable-next-line no-nested-ternary
  return !ele || ele === document.body ? document.body : isScrollable(ele) ? ele : getScrollableParent(ele.parentNode)
}

const getElementWithAttributes = (name: string, attrs?: Record<string, any>, events?: Record<string, any>) => {
  const el = document.createElement(name)

  if (!el) throw new Error(`Element with name ${name} can't be created.`)

  if (attrs) {
    Object.entries(attrs).forEach(([key, val]) => el.setAttribute(key, val))
  }

  if (events) {
    Object.entries(events).forEach(([key, val]) => el.addEventListener(key, val))
  }

  return el
}

export const TableRow = Node.create<TableRowOptions>({
  name: 'tableRow',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  content: '(tableCell | tableHeader)*',

  tableRole: 'row',

  parseHTML() {
    return [{ tag: 'tr' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['tr', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addNodeView() {
    return ({ editor, HTMLAttributes, getPos }) => {
      const pos = () => (getPos as () => number)()

      const scrollableParent = getScrollableParent(editor.options.element) as HTMLDivElement
      const getNodeHTML = (node: any, schema: any): string => {
        const div = document.createElement('div')
        const fragment = DOMSerializer.fromSchema(schema).serializeFragment(node.content)
        div.appendChild(fragment)
        return div.innerHTML
      }
      let isCursorInsideControlSection = false
      const actions = {
        deleteRow: () => {
          const resolvedCell = editor.state.doc.resolve(pos())
          const { tr } = this.editor.state
          if (resolvedCell.nodeAfter) {
            tr.delete(resolvedCell.pos, resolvedCell.pos + resolvedCell.nodeAfter.nodeSize)
          }
          this.editor.view.dispatch(tr)
        },
        selectRow: () => {
          const from = pos()

          const resolvedFrom = editor.state.doc.resolve(from)

          const nodeSel = new NodeSelection(resolvedFrom)

          editor.view.dispatch(editor.state.tr.setSelection(nodeSel))
        },
        moveRowUp: () => {
          const { state, view } = editor
          const { schema, tr } = state
          const from = pos()

          // Resolve the position of the current row
          const resolvedFrom = state.doc.resolve(from)
          const nodeSel = new NodeSelection(resolvedFrom)
          const currentRow = nodeSel.node

          // Find the previous row
          const previousRowResolved = state.doc.resolve(from - 1)
          if (!previousRowResolved.node()) return // If there's no previous row, don't move
          const previousRow = previousRowResolved.node()

          // Calculate positions for the transaction
          const previousRowStart = from - previousRow.nodeSize

          // Transaction: Remove the current and previous rows, then swap their positions
          tr.delete(previousRowStart, nodeSel.to)
            .insert(previousRowStart, currentRow) // Insert the current row at the position of the previous row
            .insert(previousRowStart + currentRow.nodeSize, previousRow) // Insert the previous row after the current row

          // Dispatch the transaction
          view.dispatch(tr)
        },

        moveRowDown: () => {
          const { state, view } = editor
          const { schema, tr } = state
          const from = pos()

          const resolvedFrom = state.doc.resolve(from)
          const nodeSel = new NodeSelection(resolvedFrom)
          const currentRow = nodeSel.node

          // Resolve the next row
          const currentRowEnd = from + currentRow.nodeSize
          const nextRowResolved = state.doc.resolve(currentRowEnd + 1)
          const nextRow = nextRowResolved.node()
          const nextRowStart = currentRowEnd
          const nextRowEnd = nextRowStart + nextRow.nodeSize
          // Delete the next row
          tr.delete(from, nextRowEnd)
            .insert(from, nextRow)
            .insert(from + nextRow.nodeSize, currentRow)
          // Dispatch the transaction
          view.dispatch(tr)
        },
      }

      const setCursorInsideControlSection = () => {
        isCursorInsideControlSection = true
      }

      const setCursorOutsideControlSection = () => {
        isCursorInsideControlSection = false
      }

      const controlSection = getElementWithAttributes(
        'section',
        {
          class:
            'absolute hidden flex items-center bg-black rounded-full h-[55px] min-h-[55px] max-h-[55px] flex-col z-50 cursor-pointer justify-center w-[18px]',
          contenteditable: 'false',
        },
        {
          mouseenter: () => {
            setCursorInsideControlSection()
          },
          mouseover: () => {
            setCursorInsideControlSection()
          },
          mouseleave: () => {
            setCursorOutsideControlSection()
            hideControls()
          },
        },
      )
      const deleteButton = getElementWithAttributes(
        'button',
        {
          class: 'text-xs opacity-75 hover:opacity-100 text-white text-[9px]',
        },
        {
          click: (e: any) => {
            if (e) stopPrevent(e)

            actions.deleteRow()
          },
        },
      )
      const moveUpButton = getElementWithAttributes(
        'button',
        {
          class: 'text-xs opacity-75 hover:opacity-100 text-white text-[9px]',
        },
        {
          click: (e: any) => {
            if (e) stopPrevent(e)

            actions.moveRowUp()
          },
        },
      )
      const moveDownButton = getElementWithAttributes(
        'button',
        {
          class: 'text-xs opacity-75 hover:opacity-100 text-white text-[9px]',
        },
        {
          click: (e: any) => {
            if (e) stopPrevent(e)
            actions.moveRowDown()
          },
        },
      )

      const showControls = () => {
        repositionControlsCenter()
        controlSection.classList.remove('hidden')
      }

      const hideControls = () => {
        setTimeout(() => {
          if (isCursorInsideControlSection) return
          controlSection.classList.add('hidden')
        }, 100)
      }

      // const tableRow = getElementWithAttributes("tr", { class: "content" });
      const tableRow = getElementWithAttributes(
        'tr',
        { ...HTMLAttributes },
        {
          mouseenter: showControls,
          mouseover: showControls,
          mouseleave: hideControls,
        },
      )

      moveUpButton.innerHTML = '<i class="fas fa-arrow-up"></i>'
      moveDownButton.innerHTML = '<i class="fas fa-arrow-down"></i>'
      deleteButton.innerHTML = '<i class="fas fa-trash"></i>'
      controlSection.append(moveUpButton)
      controlSection.append(deleteButton)
      controlSection.append(moveDownButton)

      document.body.append(controlSection)

      let rectBefore = ''

      const repositionControlsCenter = () => {
        setTimeout(() => {
          const rowCoords = tableRow.getBoundingClientRect()
          const stringifiedRowCoords = JSON.stringify(rowCoords)

          if (rectBefore === stringifiedRowCoords) return
          const controlSectionCords = controlSection.getBoundingClientRect()
          console.log(rowCoords, controlSectionCords)
          controlSection.style.top = `${
            rowCoords.top + (rowCoords.height / 2 - 27) + document.documentElement.scrollTop
          }px`
          controlSection.style.left = `${rowCoords.x + document.documentElement.scrollLeft - 12}px`
          controlSection.style.height = `${rowCoords.height + 1}px`

          rectBefore = stringifiedRowCoords
        })
      }

      setTimeout(() => {
        repositionControlsCenter()
      }, 100)

      editor.on('selectionUpdate', repositionControlsCenter)
      editor.on('update', repositionControlsCenter)
      scrollableParent?.addEventListener('scroll', repositionControlsCenter)
      document.addEventListener('scroll', repositionControlsCenter)

      const destroy = () => {
        controlSection.remove()
        editor.off('selectionUpdate', repositionControlsCenter)
        editor.off('update', repositionControlsCenter)
        scrollableParent?.removeEventListener('scroll', repositionControlsCenter)
        document.removeEventListener('scroll', repositionControlsCenter)

        // Remove event listeners when the row is destroyed
        /*controlSection.removeEventListener('dragstart', dragstartHandler)
        controlSection.removeEventListener('dragover', dragoverHandler)
        controlSection.removeEventListener('dragenter', dragenterHandler)
        controlSection.removeEventListener('dragleave', dragleaveHandler)
        controlSection.removeEventListener('drop', dropHandler)*/
      }

      return {
        dom: tableRow,
        contentDOM: tableRow,
        destroy,
      }
    }
  },
})
