import { cn, ButtonGroup, Button, useDisclosure, Textarea, ScrollShadow, Spinner, Tooltip } from '@nextui-org/react'

import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSocketContextData } from 'src/context/socket'

import api, { baseURL } from 'src/lib/api'
import { CheckIcon } from 'lucide-react'
import { Icon } from '@iconify/react'
import { TipTapEditor } from 'src/pages/ursPage/extensions/tiptap'

import { useWorkspaceContextData } from 'src/context/workspace'
import { URSChat } from 'src/pages/ursPage/urschat'
import DocumentSettings from 'src/pages/ursPage/DocumentSettings'
import DocumentPreview from 'src/pages/ursPage/DocumentPreview'

import Mixpanel from 'src/lib/mixpanel'

import FeedbackBar from './FeedbackBar'
import toast from 'react-hot-toast'

const CollapsibleDiv = (props) => {
  const [isOpen, setIsOpen] = useState(false) // State to manage the open/close status
  const [answer, setAnswer] = useState(props.userResponse ?? '')
  // Toggle function to open/close the div
  const toggleDiv = () => {
    setIsOpen((prev) => !prev)
  }
  return (
    <div className="container mx-auto cursor-pointer">
      <div
        onClick={toggleDiv}
        className={'px-2 py-3 rounded-xl border-1'}
        style={
          isOpen
            ? {
                backgroundColor: '#ededed',
              }
            : {}
        }
      >
        <div className={'flex flex-row items-center gap-3'}>
          <div
            className={[
              'flex border-default-500 border-1 justify-center items-center align-middle rounded-full w-[34px] h-[34px]',
              isOpen ? 'border-2 border-secondary' : '',
              props.userResponse ? 'bg-secondary border-secondary' : isOpen ? 'bg-transparent ' : '',
            ].join(' ')}
          >
            {props.userResponse ? (
              <span className={'text-white'}>
                <CheckIcon />
              </span>
            ) : (
              <h3 className={`text-md ${isOpen ? 'text-secondary' : 'text-default-600'} font-medium `}>
                {props.number}
              </h3>
            )}
          </div>
          <div className={'flex flex-1 flex-col text-left'}>
            <h3 className={'text-md font-medium'}>{props.section || props.question}</h3>
            {props.section && <span className={'text-sm font-regular text-default-600'}>{props.question}</span>}
          </div>
        </div>
        <div
          className={`mt-4 transition-all duration-500 ease-in-out ${isOpen ? 'visible' : 'hidden'} overflow-hidden`}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="pl-4 flex flex-col text-left" style={{ marginLeft: 29 }}>
            {props.suggestions?.map((suggestion: string) => (
              <Button
                variant={answer === suggestion ? 'solid' : 'bordered'}
                color="secondary"
                className={[
                  'text-left font-medium text-black border-default-400 border-1 mb-2 justify-start',
                  answer === suggestion ? 'text-white border-secondary' : '',
                ].join(' ')}
                onClick={() => {
                  setAnswer(suggestion)
                }}
                style={{
                  whiteSpace: 'normal',
                  textAlign: 'left',
                  height: 'auto',
                  minHeight: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <span>{suggestion.replace('- ', '')}</span>
              </Button>
            ))}
            <Textarea
              variant={'bordered'}
              placeholder={'Enter your own answer in keywords …'}
              defaultValue={props.suggestions?.indexOf(props.userResponse) > -1 ? '' : props.userResponse}
              classNames={{
                inputWrapper: 'border-1 border-default-400',
              }}
              onChange={(e) => setAnswer(e.target.value)}
              onKeyDown={(e) => {
                if (e.shiftKey && e.key === 'Enter') {
                  e.preventDefault()
                  props.onQuestionAnswer(e.target.value)
                  toggleDiv()
                }
              }}
            />
            <Button
              color={'secondary'}
              variant={'solid'}
              className={'w-auto mt-2 block self-end pl-4 pr-4 text-white'}
              onClick={() => {
                props.onQuestionAnswer(answer)
                toggleDiv()
              }}
            >
              Add to document
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default function UrsPage({ isReadOnly }: { isReadOnly?: boolean }) {
  const navigate = useNavigate()
  const { activeWorkspace } = useWorkspaceContextData()
  const { t } = useTranslation()
  const { ursUUID } = useParams()
  const { io } = useSocketContextData()
  const { isOpen: isOpenFinal, onOpen: onOpenFinal, onClose: onCloseFinal } = useDisclosure()

  const [urd, setUrd] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [initialized, setInitizalied] = useState<boolean>(false)
  const [ursDocument, setUrsDocument] = useState<string>('')
  const [tableContent, setTableContent] = useState<any[]>([])
  const [documentLoading, setDocumentLoading] = useState(false)
  const [isAISidebarOpen, setIsAISidebarOpen] = useState<boolean>(true)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [showStromAI, setShowStromAI] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [currentActiveWorkspace, setCurrentActiveWorkspace] = useState(activeWorkspace ?? '')

  const crepRef = useRef(null)
  const tiptapEditorRef = useRef(null)

  const downloadPdf = () => {
    const popup = window.open(`${baseURL}/urs/export/${urd.uuid}`)
    if (popup) {
      popup?.focus()
      popup.addEventListener('unload', () => {
        popup.close()
      })
    }
  }
  const loadURS = async () => {
    const res = await api.get(`/urs/${ursUUID}`)
    setUrd(res.data)
    if (res.data?.document) {
      setUrsDocument(res.data?.document)
      tryChangeUrs(res.data?.document, 5)
    }
    setInitizalied(true)
  }
  const tryChangeUrs = async (content: string, tryNumber: number) => {
    if (tryNumber > 0) {
      try {
        if (crepRef && crepRef.current?.isReady?.()) {
          crepRef.current?.updateMarkdown(content)
        } else {
          setTimeout(() => tryChangeUrs(content, tryNumber - 1), 500)
        }
      } catch (err) {
        console.log('trying to create urs number ', tryNumber)
        setTimeout(() => tryChangeUrs(content, tryNumber - 1), 500)
      }
    }
  }
  const askAI = async (question: string) => {
    const currentDocument = tiptapEditorRef.current?.getMarkdown()
    const result = await io.emitWithAck('askUrsAgent', {
      ursUUID,
      question,
      token: localStorage.getItem('accessToken'),
      currentDocument,
    })

    const parts = result.split('@@URD@@')
    if (parts.length > 1) {
      setUrsDocument(parts[1] ?? ursDocument)
    }
    return parts[0]
  }

  useEffect(() => {
    if (urd) {
      const ursListInChat = urd?.chat
        ?.filter((item) => item.content.includes('# *User Requirements Document (URD)*'))
        .pop()
      if (ursListInChat) {
        setUrsDocument(urd.docuemnt ?? ursListInChat.content)
        tryChangeUrs(urd.docuemnt ?? ursListInChat.content, 5)
      } else if (urd.docuemnt) {
        setUrsDocument(urd.docuemnt)
        tryChangeUrs(urd?.document, 5)
      }
    }
  }, [urd])

  useEffect(() => {
    loadURS()
  }, [])

  useEffect(() => {
    if (activeWorkspace !== currentActiveWorkspace) {
      navigate('/urs')
    }
  }, [activeWorkspace])

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="flex flex-row w-full content-stretch h-screen bg-white">
        {initialized ? (
          <div className="flex relative w-full h-full gap-2 overflow-hidden">
            {/* Main Content Area */}
            <div
              className={cn(
                'flex px-4 py-0 grow h-full gap-2 relative transition-all duration-500 ease-in-out',
                isAISidebarOpen ? 'basis-2/3 2xl:basis-3/4' : 'basis-full justify-center',
              )}
            >
              <div
                className={cn(
                  isAISidebarOpen ? 'w-2/3' : 'w-full',
                  'flex flex-col flex-1 overflow-y-scroll no-scrollbar items-center transition-all duration-500 ease-in-out',
                )}
              >
                {/* Doc Toolbar */}
                <div className="flex justify-end mb-3 w-full max-w-[1000px] pt-4">
                  {!isReadOnly && (
                    <Tooltip content="Document settings" radius="sm">
                      <Button
                        variant="light"
                        className="text-default-500"
                        onClick={() => {
                          // Track opening document settings
                          Mixpanel.track('Document settings button clicked', {
                            location: 'Document Editor',
                          })
                          setIsSettingsOpen((prev) => !prev)
                        }}
                      >
                        <Icon icon="lucide:settings" width="20" />
                      </Button>
                    </Tooltip>
                  )}
                  <ButtonGroup className="border rounded-xl">
                    <Tooltip content="Preview document" radius="sm">
                      <Button
                        isIconOnly
                        variant="light"
                        className="text-default-500"
                        onClick={() => {
                          // Track document preview
                          Mixpanel.track('Preview document button clicked', {
                            location: 'Document Editor',
                          })
                          setIsPreview(true)
                        }}
                      >
                        <Icon icon="lucide:scroll-text" width="20" />
                      </Button>
                    </Tooltip>
                    <Tooltip content="Download document (PDF)" radius="sm">
                      <Button
                        isIconOnly
                        variant="light"
                        className="text-default-500"
                        onClick={() => {
                          // Track document download
                          Mixpanel.track('Download document button clicked', {
                            format: 'PDF',
                            location: 'Document Editor',
                          })
                          downloadPdf()
                        }}
                      >
                        <Icon icon="lucide:download" width="20" />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                  {!isReadOnly && (
                    <>
                      <Tooltip content="Share this document" radius="sm">
                        <Button
                          variant="solid"
                          className="ml-2"
                          color="secondary"
                          onClick={() => {
                            try {
                              const url = `${document.location.origin}/document/${urd.uuid}`
                              navigator.clipboard.writeText(url)
                              toast.success('Link copied to clipboard')
                              Mixpanel.track('Document link copied', {
                                location: 'Document Editor',
                                document_id: urd.uuid,
                                link: url,
                              })
                            } catch (error) {
                              toast.error('Failed to copy the link. Please try again.')
                              console.error('Error copying link:', error)
                            }
                          }}
                        >
                          <Icon icon="solar:square-share-line-linear" width="20" />
                          Share
                        </Button>
                      </Tooltip>
                      <Tooltip content="Toggle AI sidebar" radius="sm">
                        <Button
                          variant="light"
                          className="text-default-500"
                          onClick={() => {
                            // Track toggling AI sidebar
                            Mixpanel.track('Toggle AI sidebar button clicked', {
                              location: 'Document Editor',
                              sidebar_state: isAISidebarOpen ? 'closed' : 'open',
                            })
                            setIsAISidebarOpen((prev) => !prev)
                          }}
                        >
                          {isAISidebarOpen ? (
                            <Icon icon="lucide:sidebar-open" width="20" />
                          ) : (
                            <Icon icon="lucide:sidebar-close" width="20" />
                          )}
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </div>

                {/* Doc Editor */}
                <div className="flex border rounded-xl px-2 md:px-20 py-2 md:py-8 max-w-[1000px] mb-4">
                  <TipTapEditor
                    ref={tiptapEditorRef}
                    markdown={ursDocument}
                    ursUUID={urd.uuid}
                    isReadOnly={isReadOnly}
                  />
                </div>

                {/* Doc Feedback Bar */}
                <div className="flex w-full sticky bottom-2 justify-center z-30">
                  {(urd.score === undefined || urd.score === -1) && !isReadOnly && (
                    <FeedbackBar ursUUID={ursUUID as string} />
                  )}
                </div>

                {/* Doc Save Info */}
                {documentLoading && (
                  <div className={'fixed w-full h-full top-0 left-0 z-30'}>
                    <div
                      className={
                        'bg-black/75 backdrop-blur-sm w-full h-full justify-center items-center flex flex-col text-2xl text-white gap-3'
                      }
                    >
                      <div className={'flex flex-row gap-2 justify-center items-center'}>
                        <Spinner size={'lg'} color="secondary" />
                        <span>Adding new information to document…</span>
                      </div>
                      <span className={'text-white text-sm opacity-60 text-center'}>
                        It can take up to 30 seconds.
                        <br />
                        Please wait and keep this browser tab open.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!isReadOnly && (
              <>
                {isSettingsOpen && (
                  <div
                    className={cn(
                      'absolute top-0 right-0 h-full bg-white overflow-hidden border border-l-1 border-y-0 border-r-0 border-divider transition-transform duration-500 ease-in-out w-1/3 z-30 px-4',
                      isSettingsOpen && isAISidebarOpen ? 'translate-x-0' : 'translate-x-full',
                    )}
                  >
                    <DocumentSettings
                      isSettingsOpen={isSettingsOpen}
                      close={() => setIsSettingsOpen(false)}
                      urs={ursDocument}
                    />
                  </div>
                )}
                <ScrollShadow
                  className={cn(
                    'w-1/3 z-20 overflow-auto h-full border border-divider border-l-1 border-y-0 border-r-0 transition-transform duration-500 ease-in-out',
                    isAISidebarOpen ? 'translate-x-0' : 'translate-x-full',
                  )}
                  hideScrollBar
                  size={100}
                  id="questions"
                >
                  <div
                    className={cn(
                      'flex text-black flex-col px-4 h-full bg-white transition-all duration-500 ease-in-out',
                      isAISidebarOpen ? 'translate-x-0' : 'translate-x-full',
                    )}
                  >
                    <div className={'flex flex-row my-4'}>
                      <div className={'flex flex-row justify-center flex-1 align-middle'}>
                        <ButtonGroup>
                          <Button
                            onClick={() => setShowStromAI(false)}
                            variant={showStromAI ? 'bordered' : 'solid'}
                            color={'secondary'}
                            className={showStromAI ? 'text-secondary' : 'text-white'}
                            startContent={<Icon icon="lucide:scroll-text" width="20" />}
                          >
                            <span className={showStromAI ? 'text-secondary' : 'text-white'}>Questions Picker</span>
                          </Button>
                          <Button
                            onClick={() => setShowStromAI(true)}
                            variant={!showStromAI ? 'bordered' : 'solid'}
                            color={'secondary'}
                            startContent={
                              <Icon
                                className={!showStromAI ? 'text-secondary' : 'text-white'}
                                icon="lucide:zap"
                                width="20"
                              />
                            }
                          >
                            <span className={!showStromAI ? 'text-secondary' : 'text-white'}>Strom AI</span>
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                    {showStromAI ? (
                      <URSChat variant={'light'} urd={urd} askAI={askAI} setLoading={setLoading} />
                    ) : (
                      <div>
                        <h2 className={'text-xl font-bold'}>Suggested questions</h2>
                        <p className={'text-sm text-gray-500 leading-5 mb-2'}>
                          Choose relevant questions, provide your answers with keywords, or select from suggestions, and
                          easily add them to your document.
                        </p>
                        <div className={'flex-1 min-h-0 max-h-full flex flex-col gap-2 py-2'}>
                          {urd.questions?.map((query, index) => (
                            <CollapsibleDiv
                              key={`question_${query.question}`}
                              number={index + 1}
                              section={query.section}
                              question={query.question}
                              suggestions={query.responses}
                              userResponse={query.userResponse}
                              onQuestionAnswer={async (answer: string) => {
                                // Track interaction with Questions Picker
                                Mixpanel.track('Questions picker used', {
                                  question: query.question,
                                  selected_answer: answer,
                                  answer_type: query.responses.includes(answer) ? 'suggested' : 'custom', // Indicates if the answer was suggested or custom-defined
                                  section: query.section,
                                  question_index: index,
                                })

                                setDocumentLoading(true)
                                setUrd((prev) => {
                                  const questions = prev.questions
                                  questions[index].userResponse = answer
                                  return {
                                    ...prev,
                                    questions,
                                  }
                                })
                                const document = tiptapEditorRef.current?.getMarkdown()

                                const newURS = await io.emitWithAck('answerQuestionForDocument', {
                                  answer,
                                  index,
                                  document,
                                  ursUUID,
                                  question: query.question,
                                  token: localStorage.getItem('accessToken'),
                                })
                                //tiptapEditorRef.current?.setMarkdown(newURS.document)
                                setUrsDocument(newURS.document)
                                setDocumentLoading(false)
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollShadow>
              </>
            )}
            {isPreview && (
              <div className={'flex absolute top-0 left-0 w-full h-full z-50 bg-zinc-800'}>
                <div className="flex w-full h-full">
                  <DocumentPreview url={`${baseURL}/urs/preview/${urd.uuid}`} close={() => setIsPreview(false)} />
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="flex flex-1 flex-col justify-center content-center items-center">
              <div className={'w-full h-full justify-center items-center flex gap-3'}>
                <Spinner size={'lg'} color="secondary" />
                <span className={'text-black text-xl'}>Retrieving project details…</span>
              </div>
            </div>
            <div></div>
          </>
        )}
      </div>
    </>
  )
}

interface ITranscription {
  text: string
  source: string
  isNew: boolean
}

interface IInsight {
  type: string
  content: string
}

interface IImplementation {
  title: string
  description: string
}
