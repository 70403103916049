import React from 'react'
import { Lightbulb, Zap, ShieldAlert } from 'lucide-react'

import FeatureCard from './feature-card'

const featuresCategories = [
  {
    key: 'examples',
    title: 'Examples',
    icon: <Lightbulb size={30} />,
    descriptions: [
      'Design a CRM layout for a healthcare provider',
      'Generate a CRM plan for an e-commerce company',
      'Create a workflow for a financial services firm',
    ],
  },
  {
    key: 'capabilities',
    title: 'Powerdocs capabilities',
    icon: <Zap size={30} />,
    descriptions: [
      'Asks for clarification when needed',
      'Remembers what you said earlier',
      'Adapts to your preferences over time',
    ],
  },
  {
    key: 'limitations',
    title: 'Powerdocs limitations',
    icon: <ShieldAlert size={30} />,
    descriptions: [
      'Supports only Salesforce Sales Cloud',
      'No data logic yet, only UI/UX design',
      'Can not modify existing SFDC orgs yet',
    ],
  },
]

export default function Component() {
  return (
    <div className="flex gap-3 justify-center">
      {featuresCategories.map((category) => (
        <FeatureCard
          key={category.key}
          descriptions={category.descriptions}
          icon={category.icon}
          title={category.title}
        />
      ))}
    </div>
  )
}
