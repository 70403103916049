import { ButtonGroup, Button, Image, useDisclosure, Textarea, ScrollShadow, Spinner, Input } from '@nextui-org/react'

import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSocketContextData } from 'src/context/socket'

import api, { baseURL } from 'src/lib/api'
import { CheckIcon, ChevronsRight, DownloadIcon, ChevronsLeft } from 'lucide-react'
import { TipTapEditor } from 'src/pages/ursPage/extensions/tiptap'
import { useWorkspaceContextData } from 'src/context/workspace'
const CollapsibleDiv = (props) => {
  const [isOpen, setIsOpen] = useState(false) // State to manage the open/close status
  const [answer, setAnswer] = useState(props.userResponse ?? '')
  // Toggle function to open/close the div
  const toggleDiv = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div className="container mx-auto p-4">
      <div
        onClick={toggleDiv}
        className={'px-4 py-2 rounded-2xl border-1 border-default-600 gap-4 pb-5 pt-5'}
        style={
          isOpen
            ? {
                backgroundColor: '#E0E0E0',
              }
            : {}
        }
      >
        <div className={'flex flex-row  items-center gap-4'}>
          <div
            className={[
              'flex border-default-400 border-1 justify-center items-center align-middle rounded-full w-[34px] h-[34px]',
              isOpen ? 'border-2 border-secondary' : '',
              props.userResponse ? 'bg-secondary border-secondary' : isOpen ? 'bg-transparent ' : '',
            ].join(' ')}
          >
            {props.userResponse ? (
              <span className={'text-white'}>
                <CheckIcon />
              </span>
            ) : (
              <h3 className={`text-md ${isOpen ? 'text-secondary' : 'text-default-400'} font-medium `}>
                {props.number}
              </h3>
            )}
          </div>
          <div className={'flex flex-1 flex-col text-left'}>
            <h3 className={'text-xl font-bold'}>{props.question}</h3>
            <span className={'text-sm font-regular text-default-400'}>{props.question}</span>
          </div>
        </div>
        <div
          className={`mt-4 transition-all duration-500 ease-in-out ${
            isOpen ? 'opacity-100' : 'max-h-0 opacity-0'
          } overflow-hidden`}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="pl-4 flex flex-col text-left" style={{ marginLeft: 42 }}>
            {props.suggestions?.map((suggestion: string) => (
              <Button
                variant={answer === suggestion ? 'solid' : 'bordered'}
                color="secondary"
                className={[
                  'text-left rounded-xl text-default-200 border-default-200 border-1 mb-2 justify-start',
                  answer === suggestion ? 'text-white border-secondary' : '',
                ].join(' ')}
              >
                <span>{suggestion.replace('- ', '')}</span>
              </Button>
            ))}
            <Textarea
              variant={'bordered'}
              placeholder={'Type your own answer'}
              defaultValue={props.suggestions?.indexOf(props.userResponse) > -1 ? '' : props.userResponse}
              classNames={{
                inputWrapper: 'border-1',
              }}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default function UrsPageSupervisor() {
  const [urd, setUrd] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [initialized, setInitizalied] = useState<boolean>(false)
  const { t } = useTranslation()
  const { ursUUID } = useParams()
  const { io } = useSocketContextData()
  const { isOpen: isOpenFinal, onOpen: onOpenFinal, onClose: onCloseFinal } = useDisclosure()
  const [ursDocument, setUrsDocument] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [adminLock, setAdminLock] = useState<boolean>(true)
  const loadURS = async () => {
    const res = await api.get(`/urs/${ursUUID}/supervisor?password=${password}`)
    setUrd(res.data)
    if (res.data?.document) {
      setUrsDocument(res.data?.document)
      tryChangeUrs(res.data?.document, 5)
      setInitizalied(true)
      setAdminLock(false)
    }
  }

  useEffect(() => {
    if (urd) {
      const ursListInChat = urd?.chat
        ?.filter((item) => item.content.includes('# *User Requirements Document (URD)*'))
        .pop()
      if (ursListInChat) {
        setUrsDocument(urd.docuemnt ?? ursListInChat.content)
        tryChangeUrs(urd.docuemnt ?? ursListInChat.content, 5)
      } else if (urd.docuemnt) {
        setUrsDocument(urd.docuemnt)
        tryChangeUrs(urd?.document, 5)
      }
    }
  }, [urd])
  const crepRef = useRef(null)
  const tryChangeUrs = async (content: string, tryNumber: number) => {
    if (tryNumber > 0) {
      try {
        if (crepRef && crepRef.current?.isReady?.()) {
          crepRef.current?.updateMarkdown(content)
        } else {
          setTimeout(() => tryChangeUrs(content, tryNumber - 1), 500)
        }
      } catch (err) {
        console.log('trying to create urs number ', tryNumber)
        setTimeout(() => tryChangeUrs(content, tryNumber - 1), 500)
      }
    }
  }
  useEffect(() => {}, [])

  const askAI = async (question: string) => {
    const result = await io.emitWithAck('askUrsAgent', {
      ursUUID,
      question,
      token: localStorage.getItem('accessToken'),
      currentDocument: crepRef.current?.getMarkdown?.(),
    })
    if (result.includes('@@URD@@')) {
      const parts = result.split('@@URD@@')
      if (parts.length > 1) {
        setUrsDocument(parts[1] ?? ursDocument)
      }
    }
    return result as string
  }
  const { activeWorkspace } = useWorkspaceContextData()
  const downloadPdf = () => {
    const popup = window.open(`${baseURL}/urs/export/${urd.uuid}`)
    if (popup) {
      popup?.focus()
      popup.onblur = function () {
        popup?.close()
      }
    }
  }
  const [tableContent, setTableContent] = useState<any[]>([])
  const [documentLoading, setDocumentLoading] = useState(false)
  const [isQuestionsOpen, setIsQuestionsOpen] = useState<boolean>(true)
  const tiptapEditorRef = useRef(null)
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="flex bg-white flex-row w-full content-stretch h-screen">
        {!password || adminLock ? (
          <div className="flex flex-1 m-10 flex-col items-center place-content-center">
            <Image
              src="/img/empty-state-projects.png"
              alt="no projects available"
              width={400}
              radius="none"
              className="mb-8"
            />
            <Input
              placeholder={'Enter admin password to proceed'}
              className={'mb-8'}
              type={'password'}
              onChange={(e) => setPassword(e.target.value.toString())}
            />
            <Button color={'secondary'} onPress={loadURS} style={{ color: '#fff' }}>
              {'Unlock administrator area'}
            </Button>
          </div>
        ) : initialized ? (
          <div className="flex w-full h-full gap-2 overflow-hidden">
            {/* Main Content Area */}
            <div className="flex p-4 w-3/4 2xl:basis-3/4 h-full gap-2 relative">
              {loading && (
                <div className="flex flex-1 flex-col justify-center items-center absolute w-full h-full bg-black z-10">
                  <Image
                    alt="AI loading animation"
                    height={60}
                    radius="sm"
                    src="/img/ai-loading-lottie.gif"
                    width={300}
                  />
                  <p className="text-center text-lg mt-2 max-w-[300px]">Generating ...</p>
                </div>
              )}

              <div className="relative flex flex-1 w-3/4 flex-col px-8 py-2 md:px-3 rounded-md border border-divider bg-white overflow-y-scroll no-scrollbar">
                <div className="absolute top-5 right-5 z-20">
                  <ButtonGroup variant={'outline-primary'} className="border rounded-xl border-default-500">
                    <Button onClick={downloadPdf} startContent={<DownloadIcon />} className="text-default-500">
                      Export as PDF
                    </Button>
                  </ButtonGroup>
                </div>
                {/* Replace with your actual editor */}
                <TipTapEditor ref={tiptapEditorRef} markdown={ursDocument} setTableContent={setTableContent} />
                {documentLoading && (
                  <div className={'absolute w-full h-full top-0 left-0'}>
                    <div
                      className={
                        'backdrop-blur-sm w-full h-full justify-center items-center flex flex-column rounded-xl text-3xl text-default text-bold gap-2'
                      }
                    >
                      <Spinner size={'lg'} />
                      &nbsp;
                      <span>Strom currently updating your document...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ScrollShadow
              className="flex-grow overflow-auto w-1/4 h-full border border-divider border-l-1 border-l-default-500 "
              hideScrollBar
              size={100}
              id="questions"
            >
              <div
                className={`flex text-black flex-col pl-4 pr-7 h-full bg-white transition-all duration-500 ease-in-out`}
              >
                <div>
                  <button
                    className="text-secondary focus:outline-none pt-4"
                    onClick={() => setIsQuestionsOpen((prev) => !prev)}
                  >
                    {isQuestionsOpen ? <ChevronsLeft size={24} /> : <ChevronsRight size={24} />}
                  </button>
                </div>
                <div>
                  <h2 className={'text-2xl font-bold pb-2'}>Optimize your document</h2>
                  <span className={'text-sm text-default font-medium'} style={{ color: '#71717A' }}>
                    To further define and optimize your document, I have a few questions. Pick the ones that relate to
                    your project.
                  </span>
                  <div className={'flex-1 min-h-0 max-h-full'}>
                    {urd.questions?.map((query, index) => (
                      <CollapsibleDiv
                        key={`question_${query.question}`}
                        number={index + 1}
                        question={query.question}
                        suggestions={query.response}
                        userResponse={query.userResponse}
                        onQuestionAnswer={async (answer: string) => {}}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </ScrollShadow>
          </div>
        ) : (
          <>
            <div className="flex flex-1 flex-col justify-center content-center items-center">
              <Image alt="ai loading animation" height={40} radius="sm" src="/img/ai-loading-lottie.gif" width={200} />
              Retrieving project details
            </div>
          </>
        )}
      </div>
    </>
  )
}

interface ITranscription {
  text: string
  source: string
  isNew: boolean
}

interface IInsight {
  type: string
  content: string
}

interface IImplementation {
  title: string
  description: string
}
