import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import { TableCellNodeView } from './TableCellNodeView'
import { CellSelection } from '@_ueberdosis/prosemirror-tables'

export interface TableCellOptions {
  HTMLAttributes: Record<string, any>
}

const swapCells = (tr: any, editor: any, resolvedTable: any, rowIndex: any, cellIndex: any, targetSwap: any) => {
  const { state } = editor
  const { doc } = state
  const padToRow = resolvedTable.nodeAfter.content.content?.slice(0, rowIndex).reduce((rp, a) => rp + a.nodeSize, 0)
  const resolvedRow = doc.resolve(resolvedTable.pos + padToRow + 1)
  const padToCell = resolvedRow.nodeAfter.content.content?.slice(0, cellIndex).reduce((cp, a) => cp + a.nodeSize, 0)
  const resolvedCell = doc.resolve(resolvedRow.pos + padToCell + 1)
  const padToTargetCell = resolvedRow.nodeAfter.content.content
    ?.slice(0, targetSwap)
    .reduce((cp, a) => cp + a.nodeSize, 0)
  const resolvedSwapCell = doc.resolve(resolvedRow.pos + padToTargetCell + 1)
  if (targetSwap > cellIndex) {
    tr.replaceRangeWith(
      resolvedCell.pos,
      resolvedCell.pos + resolvedSwapCell.nodeAfter.nodeSize,
      resolvedSwapCell.nodeAfter,
    )
    tr.replaceRangeWith(
      resolvedCell.pos + resolvedSwapCell.nodeAfter.nodeSize,
      resolvedCell.pos + resolvedSwapCell.nodeAfter.nodeSize + resolvedCell.nodeAfter.nodeSize,
      resolvedCell.nodeAfter,
    )
  } else {
    tr.replaceRangeWith(
      resolvedSwapCell.pos,
      resolvedSwapCell.pos + resolvedCell.nodeAfter.nodeSize,
      resolvedCell.nodeAfter,
    )
    tr.replaceRangeWith(
      resolvedSwapCell.pos + resolvedCell.nodeAfter.nodeSize,
      resolvedSwapCell.pos + resolvedCell.nodeAfter.nodeSize + resolvedSwapCell.nodeAfter.nodeSize,
      resolvedSwapCell.nodeAfter,
    )
  }
}
const deleteCells = (tr: any, editor: any, resolvedTable: any, rowIndex: any, cellIndex: any) => {
  console.log('delete cell ', cellIndex)
  const { state } = editor
  const { doc } = state
  const padToRow = resolvedTable.nodeAfter.content.content?.slice(0, rowIndex).reduce((rp, a) => rp + a.nodeSize, 0)
  const resolvedRow = doc.resolve(resolvedTable.pos + padToRow + 1)
  const padToCell = resolvedRow.nodeAfter.content.content
    ?.slice(0, Math.max(0, cellIndex))
    .reduce((cp, a) => cp + a.nodeSize, 0)
  const resolvedCell = doc.resolve(resolvedRow.pos + padToCell + 1)
  console.log('resolved cell is ', resolvedCell.nodeAfter)
  tr.delete(resolvedCell.pos, resolvedCell.pos + resolvedCell.nodeAfter.nodeSize)
}

export const TableCell = Node.create<TableCellOptions>({
  name: 'tableCell',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  content: 'block+',

  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      colwidth: {
        default: [150],
        parseHTML: (element) => {
          const colwidth = element.getAttribute('colwidth')
          const value = colwidth ? [parseInt(colwidth, 10)] : null

          return value
        },
      },
    }
  },

  tableRole: 'cell',

  isolating: false,

  parseHTML() {
    return [{ tag: 'td' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['td', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  //@ts-ignore
  addNodeView() {
    return ReactNodeViewRenderer(TableCellNodeView, {
      as: 'td',
      className: 'relative',
    })
  },

  addCommands() {
    return {
      selectRow:
        (cell) =>
        ({ tr: transaction, dispatch }) => {
          if (dispatch) {
            const position = transaction.doc.resolve(cell)
            const rowSelection = CellSelection.rowSelection(position)
            transaction.setSelection(rowSelection)
          }

          return true
        },
      removeColumn:
        (cell) =>
        ({ tr, dispatch, editor }) => {
          const { state } = editor
          const { doc } = state
          // Resolve the position of the selected cell
          const resolvedCell = state.doc.resolve(cell)
          const currentCellIndex = resolvedCell.parent.content?.content?.findIndex(
            (item) => item === resolvedCell.nodeAfter,
          )
          const rowSizeFromStartToCell = resolvedCell.parent.content?.content
            ?.slice(0, currentCellIndex)
            .reduce((sp, a) => sp + a.nodeSize, 0)
          const resolvedTableRow = doc.resolve(cell - rowSizeFromStartToCell - 1)
          const parentTable = resolvedTableRow.parent
          const rowIndex = parentTable.content.content?.findIndex((n) => n === resolvedTableRow.nodeAfter)
          const sizeForTableHead = parentTable.content.content?.slice(0, rowIndex).reduce((rp, a) => rp + a.nodeSize, 0)
          const resolvedTable = doc.resolve(resolvedTableRow.pos - sizeForTableHead - 1)
          for (let i = 0; i < resolvedTable.nodeAfter.content.content.length; i++) {
            deleteCells(tr, editor, resolvedTable, i, currentCellIndex)
          }
          dispatch(tr)
          return true
        },
      moveLeft:
        (cell) =>
        ({ tr, dispatch, editor }) => {
          const { state } = editor
          const { doc } = state
          // Resolve the position of the selected cell
          const resolvedCell = state.doc.resolve(cell)
          const currentCellIndex = resolvedCell.parent.content?.content?.findIndex(
            (item) => item === resolvedCell.nodeAfter,
          )
          const rowSizeFromStartToCell = resolvedCell.parent.content?.content
            ?.slice(0, currentCellIndex)
            .reduce((sp, a) => sp + a.nodeSize, 0)
          const resolvedTableRow = doc.resolve(cell - rowSizeFromStartToCell - 1)
          const parentTable = resolvedTableRow.parent
          const rowIndex = parentTable.content.content?.findIndex((n) => n === resolvedTableRow.nodeAfter)
          const sizeForTableHead = parentTable.content.content?.slice(0, rowIndex).reduce((rp, a) => rp + a.nodeSize, 0)
          const resolvedTable = doc.resolve(resolvedTableRow.pos - sizeForTableHead - 1)
          for (let i = 0; i < resolvedTable.nodeAfter.content.content.length; i++) {
            swapCells(tr, editor, resolvedTable, i, currentCellIndex, currentCellIndex - 1)
          }
          dispatch(tr)
          return true
        },
      moveRight:
        (cell) =>
        ({ tr, dispatch, editor }) => {
          const { state } = editor
          const { doc } = state
          // Resolve the position of the selected cell
          const resolvedCell = state.doc.resolve(cell)
          const currentCellIndex = resolvedCell.parent.content?.content?.findIndex(
            (item) => item === resolvedCell.nodeAfter,
          )
          const rowSizeFromStartToCell = resolvedCell.parent.content?.content
            ?.slice(0, currentCellIndex)
            .reduce((sp, a) => sp + a.nodeSize, 0)
          const resolvedTableRow = doc.resolve(cell - rowSizeFromStartToCell - 1)
          const parentTable = resolvedTableRow.parent
          const rowIndex = parentTable.content.content?.findIndex((n) => n === resolvedTableRow.nodeAfter)

          const sizeForTableHead = parentTable.content.content?.slice(0, rowIndex).reduce((rp, a) => rp + a.nodeSize, 0)
          const resolvedTable = doc.resolve(resolvedTableRow.pos - sizeForTableHead - 1)
          for (let i = 0; i < resolvedTable.nodeAfter.content.content.length; i++) {
            swapCells(tr, editor, resolvedTable, i, currentCellIndex, currentCellIndex + 1)
          }
          dispatch(tr)
          return true
        },
    }
  },
})
