/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useEffect, useRef, useState } from 'react'
import { NodeViewContent, NodeViewWrapper, NodeViewProps } from '@tiptap/react'
import { Editor } from '@tiptap/core'
import Tippy from '@tippyjs/react'

import './styles.scss'
import { Icon } from '@iconify/react'

interface CellButton {
  name: string
  action: (editor: Editor) => boolean
  iconClass?: string
}

const cellButtonsConfig = [
  {
    title: 'Row actions',
    actions: [
      {
        name: 'Add row above',
        action: (editor: Editor) => editor.chain().focus().addRowBefore().run(),
        iconClass: 'material-symbols:add-row-above-outline',
      },
      {
        name: 'Add row below',
        action: (editor: Editor) => editor.chain().focus().addRowAfter().run(),
        iconClass: 'material-symbols:add-row-below-outline',
      },
      {
        name: 'Remove row',
        action: (editor: Editor) => editor.chain().focus().deleteRow().run(),
        iconClass: 'mdi:table-row-remove',
        buttonClass: '!text-red-600', // Red alert color
      },
    ],
  },
  {
    title: 'Column actions',
    actions: [
      {
        name: 'Add column left',
        action: (editor: Editor) => editor.chain().focus().addColumnBefore().run(),
        iconClass: 'mdi:table-column-add-before',
      },
      {
        name: 'Add column right',
        action: (editor: Editor) => editor.chain().focus().addColumnAfter().run(),
        iconClass: 'mdi:table-column-add-after',
      },
      {
        name: 'Remove column',
        action: (editor: Editor) => editor.chain().focus().deleteColumn().run(),
        iconClass: 'mdi:table-column-remove',
        buttonClass: '!text-red-600', // Red alert color
      },
    ],
  },
  {
    title: 'Table actions',
    actions: [
      {
        name: 'Toggle header row',
        action: (editor: Editor) => editor.chain().focus().toggleHeaderRow().run(),
        iconClass: 'flowbite:toggle-header-row-outline',
      },
      {
        name: 'Toggle header column',
        action: (editor: Editor) => editor.chain().focus().toggleHeaderColumn().run(),
        iconClass: 'flowbite:toggle-header-column-outline',
      },
      // {
      //   name: 'Toggle header cell',
      //   action: (editor: Editor) => editor.chain().focus().toggleHeaderCell().run(),
      //   iconClass: 'flowbite:toggle-header-cell-outline',
      // },
      {
        name: 'Remove table',
        action: (editor: Editor) => editor.chain().focus().deleteTable().run(),
        iconClass: 'mdi:table-remove',
        buttonClass: '!text-red-600', // Red alert color
      },
    ],
  },
]

export const TableCellNodeView: FC<NodeViewProps> = ({ node, getPos, selected, editor }) => {
  const [isCurrentCellActive, setIsCurrentCellActive] = useState(false)
  const [cellOffset, setCellOffset] = useState(0)

  // Determine if the current cell is in the header row
  useEffect(() => {
    const updateHeaderState = () => {
      const { state } = editor
      const { doc } = state
      // Resolve the position of the selected cell

      const position = getPos()
      if (position === undefined) return

      const resolvedCell = state.doc.resolve(getPos())
      const currentCellIndex = resolvedCell.parent.content?.content?.findIndex(
        (item) => item === resolvedCell.nodeAfter,
      )
      const rowSizeFromStartToCell = resolvedCell.parent.content?.content
        ?.slice(0, currentCellIndex)
        .reduce((sp, a) => sp + a.nodeSize, 0)
      const resolvedTableRow = doc.resolve(getPos() - rowSizeFromStartToCell - 1)
      const parentTable = resolvedTableRow.parent
      const rowIndex = parentTable.content.content?.findIndex((n) => n === resolvedTableRow.nodeAfter)

      const sizeForTableHead = parentTable.content.content?.slice(0, rowIndex).reduce((rp, a) => rp + a.nodeSize, 0)
      const resolvedTable = doc.resolve(resolvedTableRow.pos - sizeForTableHead - 1)
      const tableDomElement = editor.view.nodeDOM(resolvedTable.pos)
      const rowDomElement = editor.view.nodeDOM(resolvedTableRow.pos)
      if (
        tableDomElement &&
        rowDomElement &&
        tableDomElement.getBoundingClientRect &&
        rowDomElement.getBoundingClientRect
      ) {
        const { top: rowTopPos } = rowDomElement.getBoundingClientRect()
        const { top: tableTopPos } = tableDomElement.getBoundingClientRect()
        const offset = rowTopPos - tableTopPos
        setCellOffset(offset)
      }
    }

    updateHeaderState()
    editor.on('transaction', updateHeaderState)

    return () => {
      editor.off('transaction', updateHeaderState)
    }
  }, [editor, getPos])
  const tableCellOptionsButtonRef = useRef<HTMLLabelElement>(null)

  const calculateActiveSateOfCurrentCell = () => {
    const { from, to } = editor.state.selection

    const nodeFrom = getPos()
    const nodeTo = nodeFrom + node.nodeSize

    setIsCurrentCellActive(nodeFrom <= from && to <= nodeTo)
  }

  useEffect(() => {
    editor.on('selectionUpdate', calculateActiveSateOfCurrentCell)

    setTimeout(calculateActiveSateOfCurrentCell, 100)

    return () => {
      editor.off('selectionUpdate', calculateActiveSateOfCurrentCell)
    }
  })

  const gimmeDropdownStyles = (): React.CSSProperties => {
    let top = tableCellOptionsButtonRef.current?.clientTop
    if (top) top += 5

    let left = tableCellOptionsButtonRef.current?.clientLeft
    if (left) left += 5

    return {
      top: `${top}px`,
      left: `${left}px`,
    }
  }
  const controllers = useRef<HTMLElement | null>(null)
  const [inControllSection, setInControllSection] = useState<boolean>(false)
  const hideControls = () => {
    controllers.current?.classList.add('hidden')
  }

  const showControls = () => {
    controllers.current?.classList.remove('hidden')
  }

  useEffect(() => {
    const cellElement = controllers.current?.closest('.table-cell') // Ensure controls are tied to the correct cell.

    const handleMouseEnter = () => {
      setInControllSection(true)
      showControls()
    }

    const handleMouseLeave = (event: MouseEvent) => {
      if (cellElement && !cellElement.contains(event.relatedTarget as Node)) {
        setInControllSection(false)
        hideControls()
      }
    }

    if (controllers.current) {
      controllers.current.addEventListener('mouseenter', handleMouseEnter)
      controllers.current.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (controllers.current) {
        controllers.current.removeEventListener('mouseenter', handleMouseEnter)
        controllers.current.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [controllers])

  // Attach similar event listeners to the cell for better transition handling.
  useEffect(() => {
    const cellElement = controllers.current?.closest('.table-cell')
    if (!cellElement) return

    const handleMouseEnterCell = () => {
      setInControllSection(true)
      showControls()
    }

    const handleMouseLeaveCell = (event: MouseEvent) => {
      if (!controllers.current?.contains(event.relatedTarget as Node)) {
        setInControllSection(false)
        hideControls()
      }
    }

    cellElement.addEventListener('mouseenter', handleMouseEnterCell)
    // @ts-ignore
    cellElement.addEventListener('mouseleave', handleMouseLeaveCell)

    return () => {
      cellElement.removeEventListener('mouseenter', handleMouseEnterCell)
      // @ts-ignore
      cellElement.removeEventListener('mouseleave', handleMouseLeaveCell)
    }
  }, [controllers])
  const deleteCells = (tr: any, editor: any, resolvedTable: any, rowIndex: any, cellIndex: any) => {
    console.log('delete cell ', cellIndex)
    const { state } = editor
    const { doc } = state
    const padToRow = resolvedTable.nodeAfter.content.content?.slice(0, rowIndex).reduce((rp, a) => rp + a.nodeSize, 0)
    const resolvedRow = doc.resolve(resolvedTable.pos + padToRow + 1)
    const padToCell = resolvedRow.nodeAfter.content.content
      ?.slice(0, Math.max(0, cellIndex))
      .reduce((cp, a) => cp + a.nodeSize, 0)
    const resolvedCell = doc.resolve(resolvedRow.pos + padToCell + 1)
    console.log('resolved cell is ', resolvedCell.nodeAfter)
    return { from: resolvedCell.pos, to: resolvedCell.pos + resolvedCell.nodeAfter.nodeSize }
  }

  return (
    <NodeViewWrapper as={'div'} className={`h-full tiptap-cell`}>
      <span onMouseEnter={showControls} onMouseLeave={hideControls} onMouseOver={showControls}>
        <NodeViewContent as="span" />

        <div
          ref={controllers}
          contentEditable={false}
          onMouseEnter={showControls}
          onMouseLeave={hideControls}
          onMouseOver={showControls}
          className={`absolute left-0 right-0 mx-auto hidden flex items-center bg-black rounded-full w-[55px] min-w-[55px] max-w-[55px] z-50 cursor-pointer justify-center h-[18px]`}
          style={{
            top: -1 * cellOffset - 10,
          }}
        >
          <section className={'w-fit flex text-xs text-white text-[9px]'}>
            <button
              className={'p-1 opacity-75 hover:opacity-100'}
              onClick={() => {
                editor.chain().focus().moveLeft(getPos(), node).run()
              }}
            >
              <i className={'fas fa-arrow-left'}></i>
            </button>
            <button
              className={'p-1 opacity-75 hover:opacity-100'}
              onClick={() => {
                const cell = getPos()
                const { state } = editor
                const { doc, tr } = state
                // Resolve the position of the selected cell
                const resolvedCell = state.doc.resolve(cell)
                const currentCellIndex = resolvedCell.parent.content?.content?.findIndex(
                  (item) => item === resolvedCell.nodeAfter,
                )
                const rowSizeFromStartToCell = resolvedCell.parent.content?.content
                  ?.slice(0, currentCellIndex)
                  .reduce((sp, a) => sp + a.nodeSize, 0)
                const resolvedTableRow = doc.resolve(cell - rowSizeFromStartToCell - 1)
                const parentTable = resolvedTableRow.parent
                const rowIndex = parentTable.content.content?.findIndex((n) => n === resolvedTableRow.nodeAfter)
                const sizeForTableHead = parentTable.content.content
                  ?.slice(0, rowIndex)
                  .reduce((rp, a) => rp + a.nodeSize, 0)
                const resolvedTable = doc.resolve(resolvedTableRow.pos - sizeForTableHead - 1)
                const ranges: { from: number; to: number }[] = []
                for (let i = 0; i < resolvedTable.nodeAfter.content.content.length; i++) {
                  ranges.push(deleteCells(tr, editor, resolvedTable, i, currentCellIndex))
                }
                // Sort ranges to avoid overlap issues, from highest to lowest.
                const sortedRanges = ranges.sort((a, b) => b.from - a.from)

                sortedRanges.forEach(({ from, to }) => {
                  tr.delete(from, to)
                })

                // Apply the transaction once.
                editor.view.dispatch(tr)
              }}
            >
              <i className={'fas fa-trash'}></i>
            </button>
            <button
              className={'p-1 opacity-75 hover:opacity-100'}
              onClick={() => {
                editor.chain().focus().moveRight(getPos(), node).run()
              }}
            >
              <i className={'fas fa-arrow-right'}></i>
            </button>
          </section>
        </div>
        {(isCurrentCellActive || selected) && (
          <>
            <Tippy
              appendTo={document.body}
              trigger="click"
              interactive
              animation="shift-toward-subtle"
              placement="auto"
              content={
                <article className="dropdown" contentEditable={false}>
                  <ul
                    tabIndex={0}
                    className="dropdown-content fixed menu menu-compact p-2 bg-base-100 rounded-xl shadow-xl border border-default-300 w-56 gap-2"
                    style={gimmeDropdownStyles()}
                  >
                    {cellButtonsConfig.map((category) => (
                      <li key={category.title}>
                        <h3 className="font-bold text-sm mb-1">{category.title}</h3>
                        {category.actions.map((btn) => (
                          <button
                            key={btn.name}
                            type="button"
                            className={`button flex items-center text-left ${btn.buttonClass || ''}`}
                            onClick={() => btn.action(editor as any)}
                          >
                            <Icon icon={btn.iconClass as string} width={20} />
                            <span>{btn.name}</span>
                          </button>
                        ))}
                      </li>
                    ))}
                  </ul>
                </article>
              }
            >
              <label tabIndex={0} className="trigger-button" contentEditable={false}>
                <Icon icon={'heroicons-outline:chevron-down'} />
              </label>
            </Tippy>
          </>
        )}
      </span>
    </NodeViewWrapper>
  )
}
